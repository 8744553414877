<template>
    <div class="about" id="quotaReviewList">
      <div style="text-align: left">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="骑手名字：">
            <el-input v-model="queryForm.name" placeholder="请输入查询" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="骑手身份证号码：">
            <el-input type="number" v-model="queryForm.idno" placeholder="请输入查询" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="display: flex;justify-content: space-between;">
        <span>计薪汇总表编号：<b>{{$route.query.payrollSummary}}</b></span>
        <span>最后更新时间：<b>{{$route.query.updateTime}}</b></span>
        <span>总记录：<b>{{total}}条</b></span>
      </div>
      <div>
          <el-table
          :data="tableData"
          :header-cell-style="tableHeader"
          :cell-style="tableCell"
          border
          fit
          style="width: 100%">
          <el-table-column  type="index"  label="序号" />
          <el-table-column :show-overflow-tooltip="true" prop="salaryMonth"  label="工资月份" /> 
          <el-table-column prop="riderName" label="骑手姓名" />
          <el-table-column prop="idno" label="身份证号" :show-overflow-tooltip="true" />
          <el-table-column prop="siteName" label="站点" :show-overflow-tooltip="true"/>
          <el-table-column prop="registerTime" label="注册日期" :show-overflow-tooltip="true"/>
          <el-table-column prop="quitTime" label="离职日期" :show-overflow-tooltip="true"/>
          <el-table-column prop="riderNature" label="骑手性质" :show-overflow-tooltip="true"/>
          <el-table-column prop="useScheme" label="失业方案" :show-overflow-tooltip="true"/>
          <el-table-column prop="attendanceDays" label="出勤天数" :show-overflow-tooltip="true"/>
          <el-table-column prop="punctuality" label="准时率" :show-overflow-tooltip="true"/>
          <el-table-column prop="completedQuantity" label="完成单量" :show-overflow-tooltip="true"/>
          <el-table-column prop="midnightSnackQuantity1" label="夜宵单量1" :show-overflow-tooltip="true"/>
          <el-table-column prop="midnightSnackQuantity2" label="夜宵单量2" :show-overflow-tooltip="true"/>
          <el-table-column prop="anemoneSingular" label="海葵单数" :show-overflow-tooltip="true"/>
          <el-table-column prop="naixueSingular" label="奈雪单数" :show-overflow-tooltip="true"/>
          <el-table-column prop="assessmentScore" label="考核分数" :show-overflow-tooltip="true"/>
          <el-table-column prop="evaluteGrade" label="评价等级" :show-overflow-tooltip="true"/>
          <el-table-column prop="quantityCommission" label="单量提成" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.quantityCommission,100)}}</template>
          </el-table-column>
          <el-table-column prop="midnightSnackSubsidy" label="夜宵单补助" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.midnightSnackSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="anemoneOrderSubsidy" label="海葵订单补助" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.anemoneOrderSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="naixueOrderSubsidy" label="奈雪订单补助" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.naixueOrderSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="baseSalary" label="底薪" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.baseSalary,100)}}</template>
          </el-table-column>
          <el-table-column prop="fullAttendance" label="全勤奖" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.fullAttendance,100)}}</template>
          </el-table-column>
          <el-table-column prop="socialSubsidy" label="社保补贴" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.socialSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="otherBasicSubsidy" label="其他基本补贴" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.otherBasicSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="highTemperatureSubsidy" label="高温补贴" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.highTemperatureSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="holidayVacationsSubsidy" label="节假日补贴" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.holidayVacationsSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="otherSubsidy" label="其他补贴" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.otherSubsidy,100)}}</template>
          </el-table-column>
          <el-table-column prop="pinctualAward" label="准时奖励" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.pinctualAward,100)}}</template>
          </el-table-column>
          <el-table-column prop="offsetAward" label="冲单奖励" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.offsetAward,100)}}</template>
          </el-table-column>
          <el-table-column prop="workingYearsAward" label="工龄奖励" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.workingYearsAward,100)}}</template>
          </el-table-column>
          <el-table-column prop="otherAward" label="其他奖励" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.otherAward,100)}}</template>
          </el-table-column>
          <el-table-column prop="recommendAward" label="推荐奖励" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.recommendAward,100)}}</template>
          </el-table-column>
          <el-table-column prop="payRetroactively" label="补发工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.payRetroactively,100)}}</template>
          </el-table-column>
          <el-table-column prop="materialDeduction" label="物料扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.materialDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="foodLossDeduction" label="餐损扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.foodLossDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="rentWaterDeduction" label="房租水电扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.rentWaterDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="vehicleLeasingDeduction" label="电动车租赁扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.vehicleLeasingDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="riderDeductionDetail" label="骑手扣款明细" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.riderDeductionDetail,100)}}</template>
          </el-table-column>
          <el-table-column prop="beaconTowerDeduction" label="烽火台扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.beaconTowerDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="insurancePremium" label="保险费" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.insurancePremium,100)}}</template>
          </el-table-column>
          <el-table-column prop="socialSecurity" label="社保" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.socialSecurity,100)}}</template>
          </el-table-column>
          <el-table-column prop="otherDeduction" label="其他扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.otherDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="wagesPayable" label="应发工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.wagesPayable,100)}}</template>
          </el-table-column>
          <el-table-column prop="grossPay" label="税前工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.grossPay,100)}}</template>
          </el-table-column>
          <el-table-column prop="socialSecurityDeduction" label="社保扣款" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.socialSecurityDeduction,100)}}</template>
          </el-table-column>
          <el-table-column prop="personalTax" label="个税" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.personalTax,100)}}</template>
          </el-table-column>
          <el-table-column prop="netSalary" label="本月实发工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.netSalary,100)}}</template>
          </el-table-column>
          <el-table-column prop="prepaidOnline" label="线上已预支" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.prepaidOnline,100)}}</template>
          </el-table-column>
          <el-table-column prop="prepaidOffline" label="线下已预支" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.prepaidOffline,100)}}</template>
          </el-table-column>
          <el-table-column prop="onlineNetSalary" label="本次线上实发工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.onlineNetSalary,100)}}</template>
          </el-table-column>
          <el-table-column prop="offlineNetSalary" label="本次线下事发工资" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{accDiv(scope.row.offlineNetSalary,100)}}</template>
          </el-table-column>

        </el-table>
        <el-pagination
        
        id="page"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          @prev-click="currentChange"
          @next-click="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import {accMul} from '../../util/calculateUtils';
  export default {
      data() {
        return {
          tableData: [],
          queryForm: {
            id: '',
            idno: '',
            name: '',
            pageNo: 1,
            pageSize: 10,
            sessionId: this.$getCookie("sessionId"),
          },
          total:0
        }
      },
      async created(){
        this.queryForm.id = this.$route.query.id;
        await this.loadTable();
      },
      methods: {
        // 加载表格
        async loadTable(){
          // .bind(this);
          let flag = this;
          this.tableLoading = true;
          await this.api.payrollSummary.payrollSummaryDetail(this.queryForm).then(res =>{
            res = res.data;
            this.tableLoading = false;
            if(res.code == 200){
              this.tableData = res.data.records;
              this.total=res.data.total;
            }else{
              this.tableData =  null;
              this.total = 0;
              this.$message.error(res.msg);
            }
          })
      },
        // 条件查询
        onQuery() {
          this.loadTable();
        },
        // 重置
        onReset(){
          console.log('onReset!');
          this.queryForm.idno='';
          this.queryForm.name='';
        },
        //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        isEntity(val){
          if(val == null || val == undefined || val === ''){
              return true;
          }else{
              return false;
          }
        },
        //除法函数
        accDiv(arg1, arg2){
           if(arg1!=null){
            var t1 = 0, t2 = 0, r1, r2;
            try {
              t1 = arg1.toString().split(".")[1].length;
            }
            catch (e) {
            }
            try {
              t2 = arg2.toString().split(".")[1].length;
            }
            catch (e) {
            }
            r1 = Number(arg1.toString().replace(".", ""));
            r2 = Number(arg2.toString().replace(".", ""));
            return (r1 / r2) * Math.pow(10, t2 - t1);
           }
           else{
            return null
           }
          }
      }
  }
  </script>
  
  <style scoped>
  
  
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  >>> .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  >>> .el-drawer.rtl {
    overflow: scroll;
  }
  
  .wageadjustment{
    margin: 0 50px;
  }
  
  </style>
  
  